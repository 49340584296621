// ? These components function with alpine + tailwind UI components
// import Swiper, {Navigation, Pagination, Mousewheel, EffectCreative, Autoplay, FreeMode} from 'swiper'
import Swiper from 'swiper';
import {Autoplay, EffectCreative, EffectFlip, Mousewheel, Navigation, Pagination} from 'swiper/modules';

window.obaia.Components = {}
window.obaia.Components.swiper = (options) => {
    return {
        swiperConstance: '',
        init() {
            let initialSlideIndex = 0
            if (options.initialSlide !== '' && typeof options.initialSlide === 'string') {
                initialSlideIndex = Array.prototype.indexOf.call(this.$el.querySelectorAll('.swiper-slide'), this.$el.querySelector('#' + options.initialSlide))
            }
            const thisElem = this.$el
            this.swiperConstance = new Swiper(thisElem, {
                modules: [Navigation, Pagination, Mousewheel, EffectCreative, EffectFlip, Autoplay],
                autoplay: options.autoplay ?? false,
                effect: options.effect ?? 'slide',
                speed: options.speed ?? 300,
                freeMode: options.freeMode ?? false,
                // cssMode: options.cssMode ?? false,
                grabCursor: options.grabCursor ?? false,
                loop: options.loop ?? false,
                autoHeight: options.autoHeight ?? false,
                slidesPerView: options.slidesPerView ?? 1,
                centeredSlides: options.centeredSlides ?? false,
                spaceBetween: options.spaceBetween ?? 16,
                initialSlide: options.initialSlide ? initialSlideIndex : options.centeredSlides ? this.$el.querySelectorAll('.swiper-slide').length / 2 : 0,
                mousewheel: {
                    forceToAxis: true,
                },
                navigation: {
                    prevEl: this.$refs.swiperPrev ?? thisElem.closest('section').querySelector('.js-swiper-button-prev'),
                    nextEl: this.$refs.swiperNext ?? thisElem.closest('section').querySelector('.js-swiper-button-next'),
                },
                pagination: options.pagination ? {
                    el: thisElem.closest('section').querySelector('.js-swiper-pagination'),
                    clickable: true,
                    // renderBullet: function (index, className) {
                    //     let names = []
                    //     thisElem.querySelectorAll('.swiper-slide').forEach((slide) => {
                    //         names.push(slide.getAttribute('data-name'))
                    //     })
                    //     return '<li class="cursor-pointer antialiased ' + className + '">' + names[index] + '</li>';
                    // }
                } : {},
                breakpoints: {
                    ...options.breakpoints
                },
            })
        }
    }
}
window.obaia.Components.menu = function (e = {
    open: !1
}) {
    return {
        init() {
            this.items = Array.from(this.$el.querySelectorAll('[role="menuitem"]')),
                this.$watch("open", (() => {
                        this.open && (this.activeIndex = -1)
                    }
                ))
        },
        activeDescendant: null,
        activeIndex: null,
        items: null,
        open: e.open,
        focusButton() {
            this.$refs.button.focus()
        },
        onButtonClick() {
            this.open = !this.open,
            this.open && this.$nextTick((() => {
                    this.$refs["menu-items"].focus()
                }
            ))
        },
        onButtonEnter() {
            this.open = !this.open,
            this.open && (this.activeIndex = 0,
                this.activeDescendant = this.items[this.activeIndex].id,
                this.$nextTick((() => {
                        this.$refs["menu-items"].focus()
                    }
                )))
        },
        onArrowUp() {
            if (!this.open)
                return this.open = !0,
                    this.activeIndex = this.items.length - 1,
                    void (this.activeDescendant = this.items[this.activeIndex].id);
            0 !== this.activeIndex && (this.activeIndex = -1 === this.activeIndex ? this.items.length - 1 : this.activeIndex - 1,
                this.activeDescendant = this.items[this.activeIndex].id)
        },
        onArrowDown() {
            if (!this.open)
                return this.open = !0,
                    this.activeIndex = 0,
                    void (this.activeDescendant = this.items[this.activeIndex].id);
            this.activeIndex !== this.items.length - 1 && (this.activeIndex = this.activeIndex + 1,
                this.activeDescendant = this.items[this.activeIndex].id)
        },
        onClickAway(e) {
            if (this.open) {
                const t = ["[contentEditable=true]", "[tabindex]", "a[href]", "area[href]", "button:not([disabled])", "iframe", "input:not([disabled])", "select:not([disabled])", "textarea:not([disabled])"].map((e => `${e}:not([tabindex='-1'])`)).join(",");
                this.open = !1,
                e.target.closest(t) || this.focusButton()
            }
        }
    }
}

window.obaia.Components.popoverGroup = function () {
    return {
        __type: "popoverGroup",
        init() {
            let e = t => {
                    document.body.contains(this.$el) ? t.target instanceof Element && !this.$el.contains(t.target) && window.dispatchEvent(new CustomEvent("close-popover-group", {
                        detail: this.$el
                    })) : window.removeEventListener("focus", e, !0)
                }
            ;
            window.addEventListener("focus", e, !0)
        }
    }
}

window.obaia.Components.popover = function ({open: e = !1, focus: t = !1} = {}) {
    const i = ["[contentEditable=true]", "[tabindex]", "a[href]", "area[href]", "button:not([disabled])", "iframe", "input:not([disabled])", "select:not([disabled])", "textarea:not([disabled])"].map((e => `${e}:not([tabindex='-1'])`)).join(",");
    return {
        __type: "popover",
        open: e,
        init() {
            t && this.$watch("open", (e => {
                    e && this.$nextTick((() => {
                            !function (e) {
                                const t = Array.from(e.querySelectorAll(i));
                                !function e(i) {
                                    void 0 !== i && (i.focus({
                                        preventScroll: !0
                                    }),
                                    document.activeElement !== i && e(t[t.indexOf(i) + 1]))
                                }(t[0])
                            }(this.$refs.panel)
                        }
                    ))
                }
            ));
            let e = i => {
                    if (!document.body.contains(this.$el))
                        return void window.removeEventListener("focus", e, !0);
                    let n = t ? this.$refs.panel : this.$el;
                    if (this.open && i.target instanceof Element && !n.contains(i.target)) {
                        let e = this.$el;
                        for (; e.parentNode;)
                            if (e = e.parentNode,
                            e.__x instanceof this.constructor) {
                                if ("popoverGroup" === e.__x.$data.__type)
                                    return;
                                if ("popover" === e.__x.$data.__type)
                                    break
                            }
                        this.open = !1
                    }
                }
            ;
            window.addEventListener("focus", e, !0)
        },
        onEscape() {
            this.open = !1,
            this.restoreEl && this.restoreEl.focus()
        },
        onClosePopoverGroup(e) {
            e.detail.contains(this.$el) && (this.open = !1)
        },
        toggle(e) {
            this.open = !this.open,
                this.open ? this.restoreEl = e.currentTarget : this.restoreEl && this.restoreEl.focus()
        }
    }
}

window.obaia.Components.radioGroup = function ({initialCheckedIndex: e = 0} = {}) {
    return {
        value: void 0,
        active: void 0,
        init() {
            let t = Array.from(this.$el.querySelectorAll("input"));
            this.value = t[e]?.value;
            for (let e of t)
                e.addEventListener("change", (() => {
                        console.log(e.value);
                        this.active = e.value
                    }
                )),
                    e.addEventListener("focus", (() => {
                            this.active = e.value
                        }
                    ));
            window.addEventListener("focus", (() => {
                    console.log("Focus change"),
                    t.includes(document.activeElement) || (console.log("HIT"), this.active = void 0
                    )
                }
            ), !0)
        }
    }
}

window.obaia.Components.searchOverlay = (options) => {
    return {
        search: '',
        results: [],
        searchOpen: options.searchOpen ?? false,
        searchOverlay: options.searchOverlay ?? false,
        init() {
            // this.$refs.search.focus()
        },
        fetch(searchValue) {
            this.search = searchValue
            if (this.search.length > 2) {
                const data = {q: this.search}
                fetch('/actions/site-module/search', {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'X-CSRF-Token': window.obaia.csrf['csrfTokenValue'],
                        'X-Requested-With': 'XMLHttpRequest',
                    },
                    body: JSON.stringify(data),
                })
                    .then(response => {
                        if (!response.ok) {
                            throw new Error('Network response was not ok')
                        }
                        return response.json()
                    })
                    .then(data => {
                        this.results = data?.results
                        this.loading = false
                    })
                    .catch(error => {
                            console.error(error)
                        }
                    )
                this.searchOpen = true
            } else {
                this.searchOpen = false
            }
        },
        isOpen() {
            // return this.searchOpen && this.results.length > 0
            return this.searchOpen
        },
        onEscape() {
            this.searchOpen = false
            this.searchOverlay = false
            this.results = []
            this.search = ''
        },
        scrollToSection(url) {
            const id = url.split("#")[1]
            const section = document.getElementById(id)
            if (section) {
                setTimeout(() => {
                    section.scrollIntoView({behavior: 'smooth'})
                }, 170);
            } else {
                window.location.href = url
            }
        }
    }
}

window.obaia.Components.schoolgids = (options) => {
    return {
        search: '',
        section: options.section ?? null,
        results: [],
        autocompleteWrapperOpen: false,
        init() {
        },
        fetch(searchValue) {
            this.search = searchValue
            if (this.search.length > 2) {
                const data = {q: this.search, section: this.section}
                fetch('/actions/site-module/search', {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'X-CSRF-Token': window.obaia.csrf['csrfTokenValue'],
                        'X-Requested-With': 'XMLHttpRequest',
                    },
                    body: JSON.stringify(data),
                })
                    .then(response => {
                        if (!response.ok) {
                            throw new Error('Network response was not ok')
                        }
                        return response.json()
                    })
                    .then(data => {
                        this.results = data?.results
                        this.loading = false
                    })
                    .catch(error => {
                            console.error(error)
                        }
                    )
                this.autocompleteWrapperOpen = true
            } else {
                this.autocompleteWrapperOpen = false
            }
        },
    }
}

window.obaia.Components.videoPlayer = (options) => {
    return {
        showVideoPlayer: false,
        init() {

        },
        setIframe(newSrc) {
            this.$refs.iframeTarget.setAttribute('src', newSrc)
        },
        openAndPlay(newSrc) {
            this.showVideoPlayer = true
            this.setIframe(newSrc)
        },
        close() {
            this.showVideoPlayer = false
            this.setIframe('')
        }
    }
}